@import './src/styles/variables';
@import './src/styles/mixins';

.buttonBox {
  position: relative;
}

.buttonShadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background: #000000;
  border: 1px solid #000000;
  border-radius: 8px;
  transform: translate(6px, -6px);
  transition: all $timeFast ease-in-out;
}

.button {
  position: relative;
  @include btnReset();
  @include flex(row, center, center, nowrap);
  @include fontMontserrat(16px, 600, 20px);
  color: $white;
  border: 1px solid $black2;
  border-radius: 8px;
  min-width: 40px;
  min-height: 40px;
  transition: all $timeFast ease-in-out;
  z-index: 1;

  &:hover {
    transform: translate(6px, -6px);
  }
}

.red {
  background: $red1;
}

.green {
  background: $green1;
}
