@mixin flex($flex-direction, $justify-content, $align-items, $flex-wrap) {
  display: flex;
  flex-direction: $flex-direction;
  justify-content: $justify-content;
  align-items: $align-items;
  flex-wrap: $flex-wrap;
}

@mixin inlineFlex($flex-direction, $justify-content, $align-items, $flex-wrap) {
  display: inline-flex;
  flex-direction: $flex-direction;
  justify-content: $justify-content;
  align-items: $align-items;
  flex-wrap: $flex-wrap;
}

@mixin fontMontserrat($fontSize, $fontWeight, $lineHeight) {
  font-family: 'Montserrat', sans-serif;
  font-size: $fontSize;
  font-weight: $fontWeight;
  line-height: $lineHeight;
}

@mixin fontRoboto($fontSize, $fontWeight, $lineHeight) {
  font-family: 'Roboto', sans-serif;
  font-size: $fontSize;
  font-weight: $fontWeight;
  line-height: $lineHeight;
}

@mixin fontGraffiti($fontSize, $fontWeight, $lineHeight) {
  font-family: 'GraffitiBlok', sans-serif;
  font-size: $fontSize;
  font-weight: $fontWeight;
  line-height: $lineHeight;
}

@mixin ulReset() {
  list-style: none;
  margin: 0;
  padding: 0;
}

@mixin btnReset() {
  background-color: transparent;
  border: none;
  padding: 0;
}

@mixin inputReset() {
  background-color: transparent;
  border: none;
  padding: 0;
}

@keyframes fading {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes cloudTripOne {
  0% {
    transform: translate(0, 25%);
  }

  7% {
    transform: translate(-100%, 50%);
    opacity: 1;
  }

  8% {
    transform: translate(-100%, 0);
    opacity: 0;
  }

  9% {
    transform: translate(calc(100vw + 150%), 0);
    opacity: 0;
  }

  10% {
    opacity: 1;
    transform: translate(calc(100vw + 100%), -66%);
  }

  15% {
    transform: translate(calc(100vw), -42%);
  }

  100% {
    transform: translate(0, 25%);
  }
}

@keyframes cloudTripTwo {
  0% {
    transform: translate(0, 75%);
  }

  15% {
    transform: translate(-100%, 65%);
    opacity: 1;
  }

  16% {
    transform: translate(-100%, 0);
    opacity: 0;
  }

  17% {
    transform: translate(calc(100vw + 150%), 0);
    opacity: 0;
  }

  18% {
    opacity: 1;
    transform: translate(calc(100vw + 100%), 125%);
  }

  25% {
    transform: translate(calc(100vw), 42%);
  }

  50% {
    transform: translate(calc(66vw), 12%);
  }

  75% {
    transform: translate(calc(33vw), 24%);
  }

  100% {
    transform: translate(0, 75%);
  }
}

@keyframes cloudTripThree {
  0% {
    transform: translate(0, 45%);
  }

  10% {
    transform: translate(-20vw, 30%);
  }

  20% {
    transform: translate(-40vw, 15%);
  }

  30% {
    transform: translate(-60vw, 5%);
  }

  40% {
    transform: translate(-80vw, 15%);
  }

  50% {
    transform: translate(-100vw, 25%);
    opacity: 1;
  }

  51% {
    transform: translate(calc(-100vw - 100%), 35%);
    opacity: 0;
  }

  52% {
    transform: translate(calc(-100vw - 100%), 0);
    opacity: 0;
  }

  53% {
    transform: translate(calc(100vw + 100%), 25%);
    opacity: 0;
  }

  54% {
    transform: translate(100vw, 35%);
    opacity: 1;
  }

  100% {
    transform: translate(0, 45%);
  }
}
