@import './src/styles/variables';
@import './src/styles/mixins';

.cloudGroupLeft {
  position: absolute;
  width: 124px;
  height: 58px;
  background-image: url('../../assets/images/cloud_1.png');
  background-repeat: no-repeat;
  background-position: left;
  background-size: contain;
  top: 80px;
  left: 0;
  z-index: 1;
  animation: cloudTripOne 30s linear infinite;
}

.cloudGroupRight {
  position: absolute;
  width: 226px;
  height: 58px;
  background-image: url('../../assets/images/cloud_2.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  top: 92px;
  left: 0;
  z-index: 2;
  animation: cloudTripTwo 25s linear infinite;
}

.cloudRight {
  position: absolute;
  width: 142px;
  height: 77px;
  background-image: url('../../assets/images/cloud_2.png');
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
  top: 80px;
  right: 0;
  z-index: 1;
  animation: cloudTripThree 33s linear infinite;
}

.isMobile {
  @media (min-width: $mobSmSide) {
    .cloudGroupLeft {
      width: 124px;
      height: 50px;
      top: 33px;
    }

    .cloudGroupRight {
      width: 165px;
      height: 50px;
      top: 57px;
    }

    .cloudRight {
      width: 224px;
      height: 77px;
      top: 53px;
    }
  }
}

.isDesktop {
  @media (min-width: $deskMd) {
    .clouds {
      .cloudGroupLeft {
        width: 181px;
        height: 54.79px;
        top: 55px;
        z-index: 1;
      }

      .cloudGroupRight {
        width: 332px;
        height: 79.34px;
        background-position: left;
        top: 70px;
        z-index: 2;
      }

      .cloudRight {
        width: 445px;
        height: 123.78px;
        top: 63px;
        z-index: 1;
      }
    }
  }
}
