@import './src/styles/variables';
@import './src/styles/mixins';

.wrapper {
  position: relative;
  min-width: 100vw;
  min-height: 100vh;
  background: $blue1;
  background-image: url('../../assets/images/horizon_sm.png');
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 100%;
  overflow: hidden;
}

.isMobile {
  @media (min-width: $mobSmSide) {
    background-image: url('../../assets/images/horizon_md.png');
  }
}

.isTablet {
  @media (min-width: $tabMd) {
    background-image: url('../../assets/images/horizon_sm.png');
  }
}

.isDesktop {
  background-image: url('../../assets/images/horizon_desk.png');

  @media screen and (min-width: $deskLg) and (max-width: $deskLg) {
    .outerShadow {
      position: absolute;
      background-image: url('../../assets/images/house_shadow_desk_outer.png');
      background-repeat: no-repeat;
      background-position: right;
      background-size: contain;
      width: 349.5px;
      height: 438px;
      top: 515px;
      right: 0;
    }
  }
}

.planetLink {
  display: block;
  width: 130px;
  height: 130px;
  position: absolute;
  top: -20px;
  right: -20px;

  @media screen and (min-width: $tabMd) {
    width: 180px;
    height: 180px;
    top: -15px;
    right: -10px;
  }

  .lottie {
    svg {
      stroke: #000;
      stroke-width: 1px;
    }
  }
}

.isMobile {
  .planetLink {
    @media screen and (min-width: $mobSmSide) {
      width: 120px;
      height: 120px;
      right: -31px;
    }

    @media screen and (min-width: $mobMdSide) {
      width: 120px;
      height: 120px;
      top: -10px;
      right: 15px;
    }
  }
}

.isDesktop {
  .planetLink {
    @media screen and (min-width: $deskSm) {
      width: 160px;
      height: 160px;
      top: 25px;
      right: -20px;
    }

    @media screen and (min-width: $deskMd) {
      width: 210px;
      height: 210px;
      right: -35px;
      top: -28px;
    }

    @media screen and (min-width: $deskLg) {
      width: 238px;
      height: 238px;
      right: -30px;
      top: -30px;
    }
  }
}
