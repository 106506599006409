@import './src/styles/variables';
@import './src/styles/mixins';

.modalFailedContent {
  @include flex(column, flex-start, center, nowrap);
  width: 100%;
  text-align: center;

  .title {
    @include fontMontserrat(24px, 600, 32px);
    color: $red1;
    margin-bottom: 22px;
  }

  .text {
    @include fontMontserrat(20px, 600, 25px);
    color: $black2;
    margin-bottom: 32px;
  }

  @media screen and (min-width: $tabMd) {
    .title {
      @include fontMontserrat(36px, 600, 44px);
      color: $red1;
      margin-bottom: 32px;
    }

    .text {
      @include fontMontserrat(24px, 600, 29px);
      color: $black2;
      margin-bottom: 42px;
    }
  }

  .button {
    @include fontRoboto(24px, 900, 28px);
    max-width: 100%;
    min-width: 100%;
    height: 45px;

    @media screen and (min-width: $tabMd) {
      max-width: 400px;
      min-width: 400px;
      height: 45px;
    }

    @media screen and (min-width: $deskMd) {
      height: 55px;
    }

    @media screen and (min-width: $deskLg) {
      height: 65px;
    }
  }
}

.modalMinting {
  @include flex(column, flex-start, center, nowrap);
  width: 100%;
  text-align: center;

  .titlePending,
  .titleComplete {
    @include fontMontserrat(24px, 600, 32px);
    color: $black2;
  }

  .titlePending {
    margin-bottom: 32px;
  }

  .titleComplete {
    margin-bottom: 22px;
  }

  .textComplete {
    @include fontMontserrat(20px, 600, 25px);
    color: $green1;
    margin-bottom: 42px;
  }

  @media screen and (min-width: $tabMd) {
    .titlePending,
    .titleComplete {
      @include fontMontserrat(36px, 600, 44px);
      color: $black2;
    }

    .titlePending {
      margin-bottom: 42px;
    }

    .titleComplete {
      margin-bottom: 32px;
    }

    .textComplete {
      @include fontMontserrat(24px, 600, 29px);
      color: $green1;
      margin-bottom: 42px;
    }
  }

  .buttons {
    @include flex(column, flex-start, center, nowrap);
    width: 100%;

    .buttonLink {
      margin-bottom: 20px;
    }

    .buttonExit {
      background: $inputBackground;
      color: $blue1;
      border-color: $blue1;
      box-shadow: 2.5px -3px 0px 3px $blue1;

      &:hover {
        box-shadow: 0 0 0px 3px $blue1 !important;
      }
    }

    .buttonLink,
    .buttonExit {
      @include fontRoboto(24px, 900, 28px);
      max-width: 100%;
      min-width: 100%;
      height: 45px;

      @media screen and (min-width: $tabMd) {
        max-width: 400px;
        min-width: 400px;
      }

      @media screen and (min-width: $deskMd) {
        height: 55px;
      }

      @media screen and (min-width: $deskLg) {
        height: 65px;
      }
    }
  }

  .buttonsComplete {
    .buttonLink,
    .buttonExit {
      max-width: 100%;
      min-width: 100%;
      height: 45px;

      @media screen and (min-width: $tabMd) {
        max-width: 450px;
        min-width: 450px;
      }

      @media screen and (min-width: $deskMd) {
        height: 55px;
      }

      @media screen and (min-width: $deskLg) {
        height: 65px;
      }
    }
  }
}
