@import './src/styles/variables';
@import './src/styles/mixins';

.inputBox {
  @include flex(row, center, center, nowrap);
}

.input {
  position: relative;
  @include fontMontserrat(15px, 600, 18px);
  text-align: center;
  color: $inputText;
  padding: 10px 5px;
  background: $inputBackground;
  border: 1px solid $black2;
  border-radius: 8px;
  box-shadow: 4px -8px 0px 0px $black2;
}
