@import './src/styles/variables';
@import './src/styles/mixins';

.cards {
  position: absolute;
  width: 203px;
  height: 202px;
  top: 72px;
  left: 54px;

  .cardBig {
    position: absolute;
    width: 132px;
    height: 132px;
    border-radius: 10px;

    &::before {
      content: '';
      display: block;
      width: 103%;
      height: 103%;
      position: absolute;
      top: 49%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-image: url('../../assets/images/card_big_border.png');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }

    img {
      border-radius: 10px;
    }
  }

  .cardSmall {
    position: absolute;
    width: 63px;
    height: 63px;
  }

  .cardSmall1 {
    top: 0;
    right: 0;
    height: 64px;
  }

  .cardSmall2 {
    top: 69px;
    right: 0;
  }

  .cardSmall3 {
    bottom: 0;
    right: 0;
  }

  .cardSmall4 {
    width: 66px;
    bottom: 0;
    left: 70px;
  }

  .cardSmall5 {
    bottom: 0;
    left: 0;
  }

  @media (min-width: $mobMd) {
    width: 248px;
    height: 247px;
    top: 78px;
    left: 29px;

    .cardBig {
      width: 157px;
      height: 157px;
    }

    .cardSmall {
      width: 74px;
      height: 74px;
    }

    .cardSmall1 {
      height: 75px;
    }

    .cardSmall2 {
      top: 82px;
    }

    .cardSmall4 {
      width: 76px;
      left: 86px;
    }
  }

  @media (min-width: $mobLg) {
    width: 268px;
    height: 267px;
    top: 98px;
    left: 38px;

    .cardBig {
      width: 167px;
      height: 167px;
    }

    .cardSmall {
      width: 84px;
      height: 84px;
    }

    .cardSmall1 {
      height: 85px;
    }

    .cardSmall2 {
      top: 89px;
    }

    .cardSmall4 {
      width: 86px;
      left: 91px;
    }
  }

  @media (min-width: $tabMd) {
    width: 313px;
    height: 312px;
    top: 103px;
    left: 39px;

    .cardBig {
      width: 199px;
      height: 199px;
    }

    .cardSmall {
      width: 94px;
      height: 94px;
    }

    .cardSmall1 {
      height: 95px;
    }

    .cardSmall2 {
      top: 103px;
    }

    .cardSmall4 {
      width: 96px;
      left: 109px;
    }
  }
}

.isMobile {
  @media (min-width: $mobSmSide) {
    width: 177px;
    height: 163px;
    top: 84px;
    left: 27px;

    .cardBig {
      width: 110px;
      height: 103px;

      &::before {
        width: 98%;
        height: 105%;
        top: 49%;
        left: 50%;
        transform: translate(-54%, -50%);
      }
    }

    .cardSmall {
      width: 52.2px;
      height: 51.95px;
    }

    .cardSmall2 {
      top: 56px;
    }

    .cardSmall4 {
      left: 62px;
    }
  }

  @media (min-width: $mobLgSide) {
    width: 219px;
    height: 207px;
    top: 102px;
    left: 41px;

    .cardBig {
      width: 143px;
      height: 132px;

      &::before {
        height: 106%;
      }
    }

    .cardSmall {
      width: 71.2px;
      height: 65.95px;
    }

    .cardSmall2 {
      top: 70px;
    }

    .cardSmall4 {
      left: 74px;
    }
  }
}

.isDesktop {
  @media (min-width: $deskSm) {
    width: 289px;
    height: 282px;
    top: 134px;
    left: 45px;

    .cardBig {
      width: 187px;
      height: 187px;
    }

    .cardSmall {
      width: 88px;
      height: 88px;
    }

    .cardSmall1 {
      top: 0;
      right: 0;
    }

    .cardSmall2 {
      top: 97px;
      right: 0;
    }

    .cardSmall3 {
      bottom: 0;
      right: 0;
    }

    .cardSmall4 {
      bottom: 0;
      left: 100px;
    }

    .cardSmall5 {
      bottom: 0;
      left: 0;
    }
  }

  @media (min-width: $deskMd) {
    width: 390px;
    height: 380px;
    top: 179px;
    left: 51px;

    .cardBig {
      width: 247px;
      height: 247px;
    }

    .cardSmall {
      width: 118px;
      height: 118px;
    }

    .cardSmall2 {
      top: 130px;
    }

    .cardSmall4 {
      left: 133px;
    }
  }

  @media (min-width: $deskLg) {
    width: 482.44px;
    height: 490.11px;
    top: 234px;
    left: 86px;

    .cardBig {
      width: 315.31px;
      height: 315.26px;
    }

    .cardSmall {
      width: 153.35px;
      height: 153.32px;
    }

    .cardSmall2 {
      top: 162px;
    }

    .cardSmall4 {
      left: 167px;
    }
  }
}
