@import './src/styles/variables';
@import './src/styles/mixins';

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-width: 100vw;
  min-height: 100vh;
  padding: 10px;
  background: rgba(91, 97, 97, 0.8);
  transition: all $timeMedium ease-in-out;
  opacity: 0;
  z-index: -1;
}

.modalShow {
  opacity: 1;
  z-index: 100;
}

.content {
  @include flex(column, flex-start, center, nowrap);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 42px;
  background: #e2dec1;
  border: 1px solid #5b6161;
  border-radius: 16px;

  @media screen and (max-width: 767px) {
    width: calc(100% - 30px);
  }
}
