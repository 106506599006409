@import './src/styles/variables';
@import './src/styles/mixins';

.house {
  position: absolute;
  background-image: url('../../assets/images/house_vertical.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  top: 50%;
  left: 50%;
  width: 350px;
  height: 580px;
  transform: translate(-50%, -50%);
  z-index: 5;

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 43px;
    left: 61px;
    width: 244px;
    height: 217px;
    background-image: url('../../assets/images/house_vertical_inner_shadow.png');
    background-repeat: no-repeat;
    background-position: top left;
    background-size: contain;
  }

  .pieceOfWall {
    position: absolute;
    background-image: url('../../assets/images/wall_sm.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    bottom: 28.17px;
    right: 48px;
    width: 33.94px;
    height: 234.83px;
    z-index: 5;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: -6px;
      left: 5.5px;
      width: 10px;
      height: 10px;
      background-color: $houseColor;
      border-radius: 50%;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: -6.5px;
      left: 4px;
      width: 10px;
      height: 10px;
      background-color: $houseColor;
      border-radius: 50%;
    }
  }

  .walletBtn {
    @include btnReset();
    @include fontRoboto(12px, 900, 16px);
    @include flex(row, center, center, nowrap);
    display: block;
    position: absolute;
    color: $black;
    background: $pink1;
    border-radius: 8px;
    box-shadow: 2px -1px 0px 3px $black;
    min-width: 210px;
    min-height: 28px;
    padding: 3px 25px 3px;
    top: 285px;
    right: 85px;
    transition: all $timeFast ease-in-out;

    &:hover {
      background: $red1;
      color: $hover1;
    }
  }

  .sidewallPicture,
  .pictureTitle {
    display: none;
  }

  @media (min-width: $mobMd) {
    width: 355px;
    height: 680px;
    margin-top: -20px;

    &::after {
      width: 294px;
      height: 257px;
      bottom: 52px;
      left: 42px;
    }

    .pieceOfWall {
      bottom: 35px;
      right: 32px;
      width: 35px;
      height: 271px;

      &::before {
        top: -7px;
        left: 6.5px;
      }
    }

    .walletBtn {
      min-width: 256px;
      min-height: 28px;
      top: 338px;
      right: 63px;
    }
  }

  @media (min-width: $mobLg) {
    width: 400px;
    height: 786px;

    &::after {
      width: 295px;
      height: 300px;
      bottom: 53px;
      left: 46px;
    }

    .pieceOfWall {
      bottom: 42.17px;
      right: 35px;
      width: 33.94px;
      height: 312.83px;

      &::before {
        top: -4px;
        left: 5px;
      }

      &::after {
        bottom: -5px;
        left: 3px;
      }
    }

    .walletBtn {
      @include fontRoboto(15px, 900, 18px);
      min-width: 290px;
      min-height: 35px;
      top: 385px;
      right: 67px;
    }
  }

  @media (min-width: $tabMd) {
    width: 450px;
    height: 875px;

    &::after {
      width: 348px;
      height: 336px;
      bottom: 58px;
      left: 54px;
    }

    .walletBtn {
      min-width: 326px;
      top: 433px;
      right: 78px;
    }
  }
}

.isMobile {
  @media (min-width: $mobSmSide) {
    background-image: url('../../assets/images/house_horizontal_sm.png');
    width: 588px;
    height: 305px;
    margin-left: 0;
    margin-top: 0;

    &::after {
      background-image: url('../../assets/images/house_horizontal_shadow_md.png');
      width: 302px;
      height: 148px;
      top: 81px;
      left: 252px;
    }

    &::before {
      content: '';
      background-image: url('../../assets/images/house_inner_shadow_md.png');
      display: block;
      position: absolute;
      width: 27.6px;
      height: 150.34px;
      top: 79px;
      left: 233px;
    }

    .sidewallPicture {
      display: block;
      position: absolute;
      background-image: url('../../assets/images/sidewall_picture.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      width: 65.07px;
      height: 107.16px;
      top: 92px;
      right: 2px;
      transform: rotate(-4deg);
    }

    .pictureTitle {
      @include fontGraffiti(12px, 400, 15px);
      color: $graffitiText;
      display: block;
      position: absolute;
      max-width: 220px;
      top: 48.6px;
      left: 27px;
    }

    .pieceOfWall {
      background-image: url('../../assets/images/wall_md.svg');
      bottom: 57px;
      right: 73px;
      width: 28px;
      height: 164px;

      &::before {
        top: -4px;
        left: 3px;
      }

      &::after {
        bottom: -5px;
        left: 1px;
      }
    }

    .walletBtn {
      @include fontRoboto(12px, 900, 16px);
      padding: 3px 12px 3px;
      min-width: 142px;
      min-height: 24px;
      top: 48px;
      right: 103px;
    }
  }

  @media (min-width: $mobLgSide) {
    width: 748px;
    height: 385px;

    &::after {
      width: 302px;
      height: 194px;
      top: 105px;
      left: 323px;
    }

    &::before {
      width: 30.6px;
      height: 206.34px;
      top: 102px;
      left: 301px;
    }

    .sidewallPicture {
      width: 87.07px;
      height: 136.16px;
      top: 119px;
      transform: rotate(-4deg);
    }

    .pictureTitle {
      @include fontGraffiti(14px, 400, 15px);
      max-width: 262px;
      top: 62.6px;
      left: 40px;
    }

    .pieceOfWall {
      background-image: url('../../assets/images/wall_md.svg');
      bottom: 70px;
      right: 96px;
      width: 33px;
      height: 209px;

      &::before {
        display: none;
      }

      &::after {
        bottom: -3.5px;
        left: -0.5px;
      }
    }

    .walletBtn {
      @include fontRoboto(15px, 900, 18px);
      min-width: 142px;
      min-height: 30px;
      top: 62px;
      right: 132px;
    }
  }
}

.isTablet {
  .pieceOfWall {
    bottom: 46px;
    right: 40px;
    width: 40px;
    height: 350px;

    &::before {
      top: -4px;
      left: 5px;
    }

    &::after {
      bottom: -6px;
      left: 4px;
    }
  }
}

.isDesktop {
  @media (min-width: $deskSm) {
    background-image: url('../../assets/images/house_desk.png');
    width: 921px;
    height: 515px;
    margin-top: 4px;

    &::before {
      content: '';
      display: block;
      position: absolute;
      background-image: url('../../assets/images/house_shadow_desk_1.png');
      background-repeat: no-repeat;
      background-position: top left;
      background-size: contain;
      width: 48.67px;
      height: 283px;
      top: 140px;
      left: 372px;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      background-image: url('../../assets/images/house_shadow_desk_2.png');
      background-repeat: no-repeat;
      background-position: top left;
      background-size: contain;
      width: 370px;
      height: 244px;
      top: 141px;
      left: 399px;
    }

    .sidewallPicture {
      display: block;
      position: absolute;
      background-image: url('../../assets/images/sidewall_picture_desk.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      width: 110px;
      height: 167px;
      top: 174px;
      left: 807px;
      transform: rotate(-4deg);
    }

    .pictureTitle {
      @include fontGraffiti(16px, 400, 18px);
      color: $graffitiText;
      display: block;
      position: absolute;
      max-width: 286px;
      top: 84px;
      left: 52px;
    }

    .feldmanLabel {
      position: absolute;
      background-image: url('../../assets/images/feldman_house_label_shadow.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      width: 130.37px;
      height: 34.72px;
      left: 41px;
      bottom: 38px;

      &::before {
        content: '';
        position: absolute;
        display: block;
        background-image: url('../../assets/images/feldman_house_label.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        transform: translate(-0.3px, 0.3px);
        width: 130.37px;
        height: 34.72px;
      }
    }

    .walletBtn {
      @include btnReset();
      @include fontRoboto(16px, 900, 20px);
      @include flex(row, center, center, nowrap);
      display: block;
      position: absolute;
      color: $black;
      background: $pink1;
      border-radius: 8px;
      box-shadow: 2px -1px 0px 4px rgba(0, 0, 0, 1);
      top: 89px;
      right: 161px;
      min-width: 158px;
      min-height: 29.93px;
      padding: 7px 25px 6px;
    }

    .pieceOfWall {
      background-image: url('../../assets/images/wall_desk.svg');
      bottom: 93px;
      right: 123px;
      width: 37px;
      height: 280px;

      &::before {
        top: 2px;
        left: 7.5px;
      }

      &::after {
        bottom: -4px;
        left: 2px;
      }
    }
  }

  @media (min-width: $deskMd) {
    width: 1211px;
    height: 675px;
    margin-top: -7px;

    &::before {
      width: 55.67px;
      height: 382px;
      top: 185px;
      left: 487.46px;
    }

    &::after {
      width: 486px;
      height: 321px;
      top: 186px;
      left: 523px;
    }

    .sidewallPicture {
      width: 147px;
      height: 243px;
      top: 211px;
      left: 1058px;
      transform: rotate(-4deg);
    }

    .pictureTitle {
      @include fontGraffiti(18px, 400, 20px);
      max-width: 325px;
      top: 118px;
      left: 65px;
    }

    .feldmanLabel {
      width: 150.37px;
      height: 54.72px;
      left: 71px;
      bottom: 38px;

      &::before {
        width: 150.37px;
        height: 54.72px;
        transform: translate(-1px, 1px);
      }
    }

    .walletBtn {
      @include fontRoboto(18px, 900, 22px);
      @include flex(row, center, center, nowrap);
      position: absolute;
      top: 118px;
      right: 208px;
      min-width: 188px;
      min-height: 40.93px;
      padding: 10px 32px 9px;
      border-radius: 8px;
      color: $black;
      background: $pink1;
      border: 1px solid $black;
      border-radius: 8px;
      box-shadow: 2px -1px 0px 4px rgba(0, 0, 0, 1);
    }

    .pieceOfWall {
      bottom: 124px;
      right: 163px;
      width: 50px;
      height: 370px;

      &::before {
        top: -4px;
        left: 5px;
      }

      &::after {
        bottom: -4px;
        left: 2px;
      }
    }
  }

  @media (min-width: $deskLg) {
    width: 1611px;
    height: 895px;

    &::before {
      width: 74.67px;
      height: 490.41px;
      top: 247.18px;
      left: 652.46px;
    }

    &::after {
      width: 639.4px;
      height: 421.04px;
      top: 248.04px;
      left: 702.14px;
    }

    .sidewallPicture {
      width: 188px;
      height: 294px;
      top: 271.84px;
      left: 1416.2px;
      transform: rotate(-4deg);
    }

    .pictureTitle {
      @include fontGraffiti(24px, 400, 26px);
      max-width: 450px;
      top: 165.6px;
      left: 90px;
    }

    .feldmanLabel {
      width: 160.37px;
      height: 64.72px;
      left: 94px;
      bottom: 60px;

      &::before {
        width: 160.37px;
        height: 64.72px;
      }
    }

    .walletBtn {
      @include fontRoboto(24px, 900, 28px);
      top: 151px;
      right: 280px;
      min-width: 266px;
      min-height: 58px;
      padding: 15px 52px 14px 49px;
      border: 2px solid $black;
      border-radius: 8px;
      box-shadow: 2px -1px 0px 4px rgba(0, 0, 0, 1);
    }

    .pieceOfWall {
      bottom: 168px;
      right: 217px;
      width: 70px;
      height: 480px;

      &::before {
        top: -1px;
        left: 15px;
      }

      &::after {
        bottom: -6px;
        left: 6px;
      }
    }
  }
}
