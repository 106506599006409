@import './src/styles/variables';
@import './src/styles/mixins';

.wrapper {
  @include flex(column, flex-start, center, nowrap);
  position: absolute;
  top: 350px;
  left: 60px;
  z-index: 5;

  .mintLeftBox {
    min-height: 30px;
    margin-bottom: 30px;

    .mintLeft {
      @include fontMontserrat(24px, 600, 29px);
      display: block;
      color: $white;
      text-shadow: -1px -1px 0 $black, 1px -1px 0 $black, -1px 1px 0 $black, 1px 1px 0 $black, 4px 0px 0px $yellow2;
      opacity: 0;
      transition: opacity $timeFast ease-in-out;
    }

    .mintLeftShow {
      opacity: 1;
    }
  }

  .countBox {
    @include flex(row, flex-start, center, nowrap);
    width: 100%;
    gap: 4px;
    margin-bottom: 37px;

    .countButton,
    .countBtnShadow {
      min-width: 35px;
      min-height: 35px;
    }
  }

  .buttonsRow {
    @include flex(row, flex-start, center, nowrap);
    width: 100%;
    gap: 4px;

    .actionButton {
      @include fontRoboto(12px, 900, 14px);
      min-width: 100px;
      min-height: 35px;
      padding: 7.5px 3.5px;
    }
  }

  .inputClass {
    max-width: 125px;
    padding: 7.5px 5px;
  }

  .openseaIcon {
    display: none;
  }

  @media (min-width: $mobMd) {
    top: 420px;
    left: 48px;

    .decrementShadow,
    .incrementShadow,
    .openseaBtnShadow,
    .mintBtnShadow,
    .inputShadow {
      display: none;
    }

    .countBox {
      .countButton,
      .countBtnShadow {
        min-width: 40px;
        min-height: 40px;
      }
    }

    .buttonsRow {
      gap: 10px;

      .actionButton {
        @include fontRoboto(12px, 900, 16px);
        min-width: 105px;
        min-height: 40px;
        padding: 10px 5.5px;
      }
    }

    .inputClass {
      max-width: 155px;
      padding: 10px 5px;
    }

    .openseaIcon {
      display: block;
      width: 16px;
      height: 16px;
      margin-left: 4px;
    }
  }

  @media (min-width: $mobLg) {
    top: 490px;
    left: 52px;

    .buttonsRow {
      gap: 10px;

      .actionButton {
        @include fontRoboto(14px, 900, 16px);
        min-width: 120px;
        padding: 10px 6px;
      }
    }

    .inputClass {
      max-width: 185px;
    }
  }
}

.isMobile {
  @media (min-width: $mobSmSide) {
    top: 102px;
    left: 257px;

    .mintLeftBox {
      margin-bottom: 3px;
      .mintLeft {
        @include fontMontserrat(18px, 600, 20px);
      }
    }

    .countBox {
      gap: 8px;
      margin-bottom: 18px;

      .countButton,
      .countBtnShadow {
        min-width: 35px;
        min-height: 35px;
      }
    }

    .inputClass {
      @include fontMontserrat(15px, 600, 18px);
      max-width: 140px;
      padding: 7.5px 5px;
    }

    .buttonsRow {
      gap: 5px;

      .actionButton {
        @include fontRoboto(12px, 900, 14px);
        min-width: 100px;
        min-height: 35px;
        padding: 6.5px 3.5px 7.5px;
      }
    }

    .openseaIcon {
      display: block;
      width: 16px;
      height: 16px;
      margin-left: 4px;
    }
  }

  @media (min-width: $mobLgSide) {
    top: 135px;
    left: 330px;

    .mintLeftBox {
      margin-bottom: 12px;
      .mintLeft {
        @include fontMontserrat(24px, 600, 29px);
      }
    }

    .countBox {
      gap: 10px;
      margin-bottom: 23px;

      .countButton,
      .countBtnShadow {
        min-width: 40px;
        min-height: 40px;
      }
    }

    .buttonsRow {
      gap: 12px;

      .actionButton {
        @include fontRoboto(14px, 900, 16px);
        min-width: 145px;
        min-height: 40px;
        padding: 9.5px 6px 10.5px;
      }
    }

    .inputClass {
      @include fontMontserrat(15px, 600, 18px);
      max-width: 190px;
      padding: 10px 5px;
    }

    .openseaIcon {
      display: block;
      width: 16px;
      height: 16px;
      margin-left: 4px;
    }
  }
}

.isTablet {
  top: 560px;
  left: 60px;

  .countBox {
    .countButton,
    .countBtnShadow {
      min-width: 50px;
      min-height: 50px;
    }
  }

  .buttonsRow {
    .actionButton {
      @include fontRoboto(16px, 900, 20px);
      min-width: 125px;
      min-height: 40px;
      padding: 10px 6px;
    }
  }

  .inputClass {
    @include fontMontserrat(24px, 600, 32px);
    max-width: 200px;
    padding: 8px 5px;
  }

  .openseaIcon {
    width: 16px;
    height: 16px;
    margin-left: 4px;
  }
}

.isDesktop {
  @media (min-width: $deskSm) {
    top: 180px;
    left: 410px;

    .countBox {
      gap: 10px;
      margin-bottom: 40px;

      .countButton,
      .countBtnShadow {
        @include fontMontserrat(24px, 600, 32px);
        min-width: 40px;
        min-height: 40px;
      }
    }

    .buttonsRow {
      gap: 10px;

      .actionButton {
        @include fontRoboto(18px, 900, 20px);
        min-width: 150px;
        padding: 10px 8px 11px;
      }
    }

    .inputClass {
      @include fontMontserrat(24px, 600, 32px);
      max-width: 248px;
      padding: 3px 5px;
      box-shadow: 6.5px -8px 0px 0px $black2;
    }

    .openseaIcon {
      width: 20px;
      height: 20px;
      margin-left: 6px;
    }

    .decrementShadow,
    .incrementShadow,
    .inputShadow,
    .inputShadow,
    .openseaBtnShadow,
    .mintBtnShadow {
      display: block;
      position: absolute;
      z-index: -1;
      filter: blur(1.5px);
      background: linear-gradient(97.38deg, rgba(35, 34, 29, 0.134) 75.47%, rgba(35, 34, 29, 0) 371.62%);
      height: 15px;
    }

    .decrementShadow,
    .incrementShadow,
    .inputShadow {
      top: 90px;
    }

    .decrementShadow {
      width: 40px;
      left: 5px;
    }

    .incrementShadow {
      width: 30px;
      right: 10px;
    }

    .inputShadow {
      width: 246px;
      left: 57px;
    }

    .openseaBtnShadow,
    .mintBtnShadow {
      bottom: -6px;
    }

    .openseaBtnShadow {
      width: 187px;
      left: 6px;
    }

    .mintBtnShadow {
      width: 144px;
      right: 0;
    }
  }

  @media (min-width: $deskMd) {
    top: 245px;
    left: 540px;

    .mintLeftBox {
      min-height: 45px;

      .mintLeft {
        @include fontMontserrat(36px, 600, 44px);
      }
    }

    .countBox {
      gap: 12px;
      margin-bottom: 50px;

      .countButton,
      .countBtnShadow {
        @include fontMontserrat(36px, 600, 44px);
        min-width: 55px;
        min-height: 55px;
      }
    }

    .buttonsRow {
      gap: 12px;

      .actionButton {
        @include fontRoboto(24px, 900, 28px);
        min-width: 200px;
        padding: 11.5px 10px;
      }
    }

    .inputClass {
      @include fontMontserrat(36px, 600, 44px);
      max-width: 320px;
      padding: 4.5px 5px;
    }

    .openseaIcon {
      width: 24px;
      height: 24px;
      margin-left: 6px;
    }

    .decrementShadow,
    .incrementShadow,
    .inputShadow {
      top: 119px;
      height: 20px;
    }

    .decrementShadow {
      left: 1.5px;
      width: 58.5px;
    }

    .incrementShadow {
      width: 43.5px;
      right: 24px;
    }

    .inputShadow {
      width: 316px;
      left: 76px;
    }

    .openseaBtnShadow,
    .mintBtnShadow {
      height: 20px;
      bottom: -8px;
    }

    .openseaBtnShadow {
      width: 244px;
      left: 4px;
    }

    .mintBtnShadow {
      width: 191px;
      right: 4px;
    }
  }

  @media (min-width: $deskLg) {
    top: 340px;
    left: 753px;

    .mintLeftBox {
      .mintLeft {
        text-shadow: -1px -1px 0 $black, 1px -1px 0 $black, -1px 1px 0 $black, 1px 1px 0 $black, 5px 0px 0px $yellow2;
      }
    }

    .countBox {
      margin-bottom: 65px;

      .countButton,
      .countBtnShadow {
        min-width: 60px;
        min-height: 60px;
      }
    }

    .buttonsRow {
      gap: 52px;

      .actionButton {
        min-width: 266px;
        min-height: 66px;
        padding: 16.5px 12px 17.5px;
      }
    }

    .inputClass {
      max-width: 465px;
      padding: 7px 5px;
    }

    .openseaIcon {
      margin-left: 10px;
    }

    .decrementShadow,
    .incrementShadow,
    .inputShadow {
      top: 122px;
    }

    .decrementShadow {
      width: 64.5px;
      left: 1.5px;
    }

    .incrementShadow {
      width: 43.5px;
      right: 10px;
    }

    .inputShadow {
      width: 448px;
      left: 76px;
    }

    .openseaBtnShadow,
    .mintBtnShadow {
      height: 21px;
      bottom: -8px;
    }

    .openseaBtnShadow {
      width: 270px;
      left: 4px;
    }

    .mintBtnShadow {
      width: 260px;
      right: 4px;
    }
  }
}
