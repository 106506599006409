$white: #fff;

$black: #000;
$black2: #0f0f26;

$red1: #bd3936;
$pink1: #eb85b6;

$yellow1: #ffd725;
$yellow2: #947d1e;

$green1: #91a725;

$blue1: #449ecd;
$blue2: #4e61c8;

$houseColor: $yellow1;

$text1: $black;
$text2: $blue2;
$graffitiText: #553c6f;

$inputText: $black2;
$inputBackground: #fffce8;

$hover1: #fffce8;

$timeSuper: 0.05s;
$timeFast: 0.15s;
$timeMedium: 0.3s;

$mobLg: 425px;
$mobMd: 414px;
$mobSm: 375px;

$mobSmSide: 667px;
$mobMdSide: 843px;
$mobLgSide: 926px;

$tabMd: 768px;

$deskSm: 1024px;
$deskMd: 1440px;
$deskLg: 1920px;
