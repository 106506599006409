@import './src/styles/variables';
@import './src/styles/mixins';

.girlLeft {
  position: absolute;
  top: 45px;
  left: 32px;
  width: 56px;
  height: 319px;
  z-index: 10;
}

.girlRight {
  position: absolute;
  top: 54px;
  right: 50px;
  width: 55px;
  height: 162px;
  z-index: 10;
}

@media (min-width: $mobMd) {
  .girlLeft {
    top: 55px;
    left: 10px;
    width: 65px;
    height: 372px;
  }

  .girlRight {
    top: 62px;
    right: 29px;
    width: 62px;
    height: 166px;
  }
}

@media (min-width: $mobLg) {
  .girlLeft {
    top: 63px;
    left: 6px;
    width: 72px;
    height: 433px;
  }

  .girlRight {
    top: 72px;
    right: 39px;
    width: 70px;
    height: 211px;
  }
}

@media (min-width: $tabMd) {
  .girlLeft {
    top: 70px;
    left: 8px;
    height: 480px;
    width: 82px;
  }

  .girlRight {
    top: 79px;
    right: 41px;
    width: 75px;
    height: 219px;
  }
}

.isMobile {
  @media (min-width: $mobSmSide) {
    .girlLeft {
      top: 30.6px;
      left: 4px;
      width: 52px;
      height: 287px;
    }

    .girlRight {
      width: 52px;
      height: 145px;
      top: 31px;
      left: 194px;
    }
  }

  @media (min-width: $mobLgSide) {
    .girlLeft {
      top: 38.6px;
      left: 6px;
      width: 107px;
      height: 350px;
    }

    .girlRight {
      width: 65px;
      height: 172px;
      top: 40px;
      left: 245px;
    }
  }
}

.isDesktop {
  @media (min-width: $deskSm) {
    .girlLeft {
      width: 90px;
      height: 470px;
      top: 53.6px;
      left: 11px;
    }

    .girlRight {
      width: 90px;
      height: 243px;
      top: 55px;
      left: 320px;
    }
  }

  @media (min-width: $deskMd) {
    .girlLeft {
      width: 122px;
      height: 635px;
      top: 70.6px;
      left: 9px;
    }

    .girlRight {
      width: 120px;
      height: 330px;
      top: 74.6px;
      left: 420px;
    }
  }

  @media (min-width: $deskLg) {
    .girlLeft {
      width: 160px;
      height: 825px;
      top: 89.6px;
      left: 14px;
    }

    .girlRight {
      width: 160px;
      height: 400px;
      top: 98.6px;
      left: 541.51px;
    }
  }
}
