@import './src/styles/variables';
@import './src/styles/mixins';

.button {
  position: relative;
  z-index: 1;
  @include fontRoboto(14px, 900, 16px);
  @include flex(row, center, center, nowrap);
  color: $black;
  background: $pink1;
  border-radius: 8px;
  border: 2px solid $black;
  box-shadow: 2.5px -3px 0px 3px $black;
  min-width: 145px;
  min-height: 40px;
  padding: 10px 8px;
  white-space: nowrap;
  transition: all $timeFast ease-in-out;

  svg path {
    transition: all $timeFast ease-in-out;
  }

  &:hover {
    transform: translate(2.5px, -3px);
    box-shadow: 0 0 0px 3px $black !important;
  }
}

.primary {
  background: $pink1;
  color: $black;

  &:hover {
    background: $red1;
    color: $hover1;
  }
}

.secondary {
  background: $inputBackground;
  color: $blue2;
  border: 2px solid $blue2;
  box-shadow: 2.5px -3px 0px 3px $blue2;

  &:hover {
    background: $pink1;
    color: $hover1;
    box-shadow: 0 0 0px 3px $blue2 !important;

    svg path {
      fill: $hover1;
    }
  }
}
