@import './mixins.scss';
@import './variables.scss';

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
  min-height: 100%;
}

@-ms-viewport {
  width: device-width;
}

body {
  line-height: 1.3;
  color: $black;
  background-color: $white;
  min-width: 320px;
  overflow: hidden;
  position: relative;
  font-family: 'Roboto', 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[tabindex='-1']:focus {
  outline: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0;
}

ul[class],
ol[class] {
  padding: 0;
}

p {
  margin: 0;
}

img {
  vertical-align: middle;
}

a {
  text-decoration: none;
  transition: color $timeFast ease-in-out;

  &:focus {
    outline: 0;
  }
}

a,
area,
button,
[role='button'],
input:not([type='range']),
label,
select,
textarea {
  touch-action: manipulation;
}

button {
  cursor: pointer;
  border-radius: 0;

  &:focus {
    outline: none;
  }
}

input::-ms-reveal,
input::-ms-clear,
input[type='password']::-ms-reveal,
input[type='password']::-ms-clear {
  display: none;
}

input::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}

input {
  box-shadow: none;
}

input,
textarea,
select {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
}

textarea {
  resize: none;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
html [type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px $white inset !important;
}

/* Change text in autofill textbox */
input:-webkit-autofill {
  -webkit-text-fill-color: $text1 !important;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.visually-hidden {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
}
